"use client"

import dynamic from "next/dynamic"
import { useAuthed } from "shared/api"
import { useClient } from "shared/lib"
import { IOrderReviewPopup } from "./order-review-popup"

const OrderReviewPopup = dynamic(() => import("./"))

export const OrderReviewPopupDynamic = (props: IOrderReviewPopup) => {
  const authed = useAuthed()
  const client = useClient()

  if (!authed) return null
  if (!client) return null

  return <OrderReviewPopup {...props} />
}
