import { OrderDetailsDto } from "shared/api"
import styles from "./order-review-card.module.scss"
import Image from "next/image"
import { analytics } from "entity/analytics"
import { ReviewRequest } from "features/review"
import { numberWithSpaces } from "shared/lib"

interface IOrderReviewCard {
  order: OrderDetailsDto
  onClose: () => void
  mutate?: () => void
}

export const OrderReviewCard = (props: IOrderReviewCard) => {
  const mainImageUrl = props.order.productPoizonImageUrls?.[0] || props.order.productImages[0]?.url

  const onClickReviewInput = () => {
    props.onClose()
    analytics.send("order_review_popup.card.review", {
      orderId: props.order.id,
    })
  }

  const pointsForReviews = props.order.pointsForReviewText + props.order.pointsForReviewPhotos

  return (
    <div className={styles.order_review_card}>
      <div className={styles.image}>
        <div className={styles.image_inner}>
          {mainImageUrl && <Image src={mainImageUrl} alt="" fill sizes="100%" unoptimized />}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.content_header}>
          {!!pointsForReviews ? (
            <div className={styles.content_header}>
              <div className={styles.points_text}>{numberWithSpaces(pointsForReviews)}₽</div>
              <div className={styles.status}>за подробный отзыв</div>
            </div>
          ) : (
            <div className={styles.content_header}>
              <div className={styles.status}>Как вам товар?</div>
            </div>
          )}
        </div>
        <ReviewRequest
          orderId={props.order.id}
          starSize={32}
          className={styles.review_input}
          mutate={props.mutate}
          onClick={onClickReviewInput}
          analyticsParamType="order-review-card"
          pointsForPhoto={props.order.pointsForReviewPhotos}
          pointsForText={props.order.pointsForReviewText}
        />
      </div>
    </div>
  )
}
